import { Application } from '@hotwired/stimulus';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Avoid page flickering on load by reverting all stimulus changes to the page
// https://www.betterstimulus.com/turbolinks/teardown.html
document.addEventListener('turbo:before-cache', () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});

export { application };
